import * as wavesStyles from './waves.module.css';

import React from 'react';

type PatternSize = 'sm' | 'lg';

type RidgesProps = Readonly<{
  size?: PatternSize;
}>;

const sizeMap: Record<PatternSize, number> = {
  sm: 90,
  lg: 295
};

const Ridges: React.FC<RidgesProps> = ({ size = 'sm' }) => {
  return (
    <div
      className={`h-full bg-secondary ${wavesStyles.waveMask}`}
      style={{ width: `${sizeMap[size]}px` }}
    />
  );
};

export default Ridges;
