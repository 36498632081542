import { Container } from "components/atoms/layout/Container";
import PageContentLayout from "components/layouts/PageContentLayout";
import StaticPageHeader from "components/molecules/banners/StaticPageHeader";
import SlateContent from "components/molecules/cms/SlateContent";
import AdvertorialCTA, {
	type AdvertorialCTAProps,
} from "components/organisms/blog/AdvertorialCTA";
import { graphql } from "gatsby";
import type {
	BlogPostPageQuery,
	CdnImageDataFragment,
} from "gatsby/graphqlTypes";
import type { AppPageProps } from "gatsby/types";
// biome-ignore lint/style/useImportType: <explanation>
import React from "react";

export { Head } from "components/atoms/Head";

export const query = graphql`
  query BlogPostPage($strapiId: Int!) {
    c: strapiBlogPost(strapiId: { eq: $strapiId }) {
      title
      summary
      rt: contentReadingTime
      c: contentSlate {
        ...SlateContent
      }
      publishedAt
      subtitle
      updatedAt
      bannerMedia {
        ...CdnImageData
      }
			advertorialCta {
				cta {
					ctaText
					content: descriptionRichTextSlate {
						...SlateContent
					}
					title
				}
				utms {
					utm_medium
					utm_source
					utm_campaign
					utm_term
					utm_content
				}
			}
    }
  }
`;

export type Props = AppPageProps<BlogPostPageQuery>;

const BlogPost: React.FC<Props> = ({ data, pageContext }) => {
	const {
		title,
		bannerMedia,
		subtitle,
		c: contentSlate,
		rt,
		updatedAt,
		publishedAt,
	} = data.c as NonNullable<BlogPostPageQuery["c"]>;
	const advertorialCta = data.c
		?.advertorialCta as unknown as AdvertorialCTAProps["data"];

	const publishedDate = publishedAt && new Date(publishedAt);
	const updatedDate = updatedAt && new Date(updatedAt);

	const showUpdatedDate =
		publishedDate &&
		updatedDate &&
		(publishedDate.getFullYear() !== updatedDate.getFullYear() ||
			publishedDate.getMonth() !== updatedDate.getMonth() ||
			publishedDate.getDay() !== updatedDate.getDay());

	const publishedDateStr =
		publishedDate &&
		new Intl.DateTimeFormat("en-GB", {
			dateStyle: "long",
		}).format(publishedDate);

	const lastUpdatedDateStr =
		updatedDate &&
		new Intl.DateTimeFormat("en-GB", {
			dateStyle: "long",
		}).format(updatedDate);

	return (
		<PageContentLayout footerNavigation={pageContext.footerNavigation}>
			<article>
				<StaticPageHeader
					large
					title={title}
					image={bannerMedia as CdnImageDataFragment}
					subtitle={subtitle}
					subtitle2={rt}
					subtitle3={`${publishedDateStr}${
						showUpdatedDate ? ` (Updated: ${lastUpdatedDateStr})` : ""
					}`}
				/>
				<Container className="my-rhythm5 flex justify-center">
					<SlateContent
						content={contentSlate}
						measure="measure"
						headingMeasure="measure-narrow"
					/>
				</Container>
				{advertorialCta && (
					<Container className="flex justify-center">
						<AdvertorialCTA data={advertorialCta} />
					</Container>
				)}
			</article>
		</PageContentLayout>
	);
};

export default BlogPost;
