import { Heading } from 'components/atoms/typography/Heading';
import { InternalLink } from 'components/atoms/typography/Link';
import SlateContent, {
  type SlateContentParsed
} from 'components/molecules/cms/SlateContent';
// biome-ignore lint/style/useImportType: <explanation>
import React from 'react';

/**
 * TODO: when migrating to Astro, this should be a Server island
 * TODO: there are issues with Query Parameter Stripping in Firefox
 */

export function utmAttributesMatch(data: AdvertorialData): boolean {
  if (typeof window === 'undefined') return false;

  const urlParams = new URLSearchParams(window.location.search);

  return data.utms.some(utm => {
    return Object.entries(utm).every(([attr, value]) => {
      if (!value) {
        return true;
      }
      return urlParams.has(attr) && urlParams.get(attr) === value;
    });
  });
}

export type AdvertorialData = {
  cta: {
    ctaText: string;
    content: SlateContentParsed;
    title: string;
  };
  utms: Array<{
    utm_medium: string;
    utm_source: string;
    utm_campaign: string;
    utm_term: string;
    utm_content: string;
  }>;
};

export type AdvertorialCTAProps = {
  data: Array<AdvertorialData>;
};

const AdvertorialCTA: React.FC<AdvertorialCTAProps> = props => {
  const match = props.data.find(utmAttributesMatch);

  if (!match) {
    return null;
  }

  const { cta } = match;

  return (
    <div className="-mt-rhythm3 mb-rhythm2 rounded-md bg-primary-200 p-rhythm1 pt-0">
      <Heading level={2}>{cta.title}</Heading>
      <SlateContent content={cta.content} />
      <div>
        👉
        <InternalLink
          withoutIcon
          to="/uk/solid-sustainable-haircare"
          className="my-rhythm0"
        >
          {cta.ctaText}
        </InternalLink>
      </div>
    </div>
  );
};

export default AdvertorialCTA;
