import { Heading, InterfaceText, TextSize } from 'components/atoms/typography';

import Balancer from 'react-wrap-balancer';
import { CdnImageDataFragment } from 'gatsby/graphqlTypes';
import { Container } from 'components/atoms/layout/Container';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import Ridges from 'components/atoms/images/ridges';
import { makeClassNames } from 'lib/util';

type StaticPageHeaderProps = PropsWithClassName &
  Readonly<{
    title: string;
    subtitle?: string | null;
    image: CdnImageDataFragment;
    large?: boolean;
    subtitle2?: string | null;
    subtitle3?: string | null;
  }>;

// Inspired by https://www.technologyreview.com/2023/02/14/1067869/rust-worlds-fastest-growing-programming-language/

const StaticPageHeader: React.FC<StaticPageHeaderProps> = ({
  className,
  title,
  subtitle,
  subtitle2,
  subtitle3,
  image,
  large
}) => {
  return (
    <div className={makeClassNames('relative', className)}>
      {/* Heading dark background */}
      <div className="absolute inset-0 h-rhythm11 bg-primary-700" />
      {/* Ridges */}
      <div className="absolute right-0 z-10 h-rhythm11 pt-rhythm2">
        <span className="lg:hidden">
          <Ridges />
        </span>
        <span className="hidden lg:inline">
          <Ridges size="lg" />
        </span>
      </div>
      <Container
        className={makeClassNames(
          !large && 'lg:flex-row',
          'relative z-20 flex flex-col items-center pt-rhythm2 lg:pt-rhythm4'
        )}
      >
        <div className="w-full pb-rhythm1 text-pearl-500 lg:flex-1">
          <Heading
            level={1}
            color=""
            bold
            withoutSpacing
            className="pb-rhythm1"
            size={TextSize.DoublePica}
          >
            <Balancer>{title}</Balancer>
          </Heading>
          {subtitle && (
            <InterfaceText
              role="doc-subtitle"
              className="block"
              color=""
              size={TextSize.Pica}
            >
              <Balancer>{subtitle}</Balancer>
            </InterfaceText>
          )}
          {subtitle2 && (
            <InterfaceText
              className="mt-rhythm0 block"
              color=""
              size={TextSize.LongPrimer}
            >
              <Balancer>{subtitle2}</Balancer>
            </InterfaceText>
          )}
          {subtitle3 && (
            <InterfaceText
              className="mt-rhythm-4 block"
              color=""
              size={TextSize.Brevier}
            >
              <Balancer>{subtitle3}</Balancer>
            </InterfaceText>
          )}
        </div>
        <ResponsiveImage
          eager
          data={image}
          className="rounded drop-shadow-xl"
          display={large ? null : 'lg:flex-1'}
          height="h-rhythm11"
        />
      </Container>
    </div>
  );
};

export default StaticPageHeader;
