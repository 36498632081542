import ResponsiveImage from "@svelte/reactify/ResponsiveImage";

import {
	BodyText,
	ExternalLink,
	Heading,
	type HeadingLevel,
	InternalLink,
	List,
	ListItem,
	type ParagraphMeasure,
	TextSize,
} from "components/atoms/typography";
import type {
	CdnImageDataFragment,
	SlateBlockNode,
	SlateContentFragment,
	SlateInlineNodeDataFragment,
	SlateTextPropsFragment,
} from "gatsby/graphqlTypes";
// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import Balancer from "react-wrap-balancer";

function slateLeafToComponent(leaf: SlateTextPropsFragment, key: React.Key) {
	if (leaf.b) {
		return <strong key={key}>{leaf.t}</strong>;
	}

	if (leaf.i) {
		return <em key={key}>{leaf.t}</em>;
	}

	if (leaf.u) {
		return <u key={key}>{leaf.t}</u>;
	}

	// NOTE: even though keyed Fragments are allowed
	// this gives the following error in production Error: Minified React error #418; visit https://reactjs.org/docs/error-decoder.html?invariant=418
	// probably because if there is no wrapper rehydration doesn't know what to do with key
	// return <React.Fragment key={key}>{leaf.t}</React.Fragment>;
	return <span key={key}>{leaf.t}</span>;
}

function slateInlineNodeToComponent(
	node: SlateInlineNodeDataFragment,
	key: React.Key,
): React.ReactElement {
	switch (node._t) {
		case "SlateLinkExternal":
			return (
				<ExternalLink key={key} url={node.url}>
					{node.ch.map((x, i) => slateLeafToComponent(x, `${key}:${i}`))}
				</ExternalLink>
			);
		case "SlateLinkInternal":
			return (
				<InternalLink key={key} to={node.href}>
					{node.ch.map((x, i) => slateLeafToComponent(x, `${key}:${i}`))}
				</InternalLink>
			);
		case "SlateText":
			return slateLeafToComponent(node, key);
		default: {
			throw Error("Unexpected slate element");
		}
	}
}

function slateElementToComponent(
	el: SlateContentFragment | SlateBlockNode,
	key: React.Key,
	measure?: ParagraphMeasure,
	headingMeasure?: ParagraphMeasure,
	compact?: boolean,
	bodyColor?: string,
): React.ReactElement {
	switch (el.t) {
		case "SlateBlockQuote":
			return (
				<blockquote key={key}>
					{el.ch.map((x, i) => slateLeafToComponent(x, `${key}:${i}`))}
				</blockquote>
			);
		case "SlateHeading": {
			const hid = `h-${key}`;
			const content = el.ch.map((x, i) =>
				slateLeafToComponent(x, `${hid}:${i}`),
			);
			const id = el.ch[0].t
				.trim()
				.replaceAll(" ", "-")
				.replaceAll(/[^-A-Z]/gi, "")
				.replace(/^-/, "")
				.replace(/-$/, "")
				.toLowerCase();
			return (
				<Heading
					level={el.l as HeadingLevel}
					id={id}
					key={hid}
					measure={headingMeasure}
				>
					<Balancer>{content}</Balancer>
				</Heading>
			);
		}
		case "SlateList": {
			const lid = `l-${key}`;
			return (
				<List ordered={el.ordered} key={lid}>
					{el.ch.map((li, i) => {
						const liid = `li-${key}-${i}`;
						return (
							<ListItem key={liid}>
								{li.ch.map((x, i) =>
									slateInlineNodeToComponent(x, `${liid}:${i}`),
								)}
							</ListItem>
						);
					})}
				</List>
			);
		}
		case "SlateParagraph": {
			const tid = `t-${key}`;

			if (
				el.ch.length === 1 &&
				el.ch[0]._t === "SlateText" &&
				el.ch[0].t.startsWith("{")
			) {
				const cdnImageData: CdnImageDataFragment = JSON.parse(
					el.ch[0].t.replace(/\u00AD/g, ""),
				);
				return (
					<ResponsiveImage
						height="h-rhythm10 md:h-rhythm11"
						key={tid}
						data={cdnImageData}
					/>
				);
			}

			return (
				<BodyText
					measure={measure}
					key={tid}
					size={compact ? TextSize.LongPrimer : TextSize.BodyCopy}
					withoutSpacing={compact}
					color={bodyColor}
				>
					{el.ch.map((ch, i) => slateInlineNodeToComponent(ch, `${tid}:${i}`))}
				</BodyText>
			);
		}
		default: {
			throw Error("Unexpected slate element");
		}
	}
}

export type SlateContentParsed = SlateContentFragment[] | SlateBlockNode[];

type SlateContentProps = {
	bodyColor?: string;
	content: SlateContentParsed;
	className?: string;
	measure?: ParagraphMeasure;
	headingMeasure?: ParagraphMeasure;
	/**
	 * NOTE: compact has limited effect right now
	 * It only affects BodyText as needed for category pages
	 */
	compact?: boolean;
};

const SlateContent: React.FC<SlateContentProps> = ({
	bodyColor,
	className,
	content,
	headingMeasure,
	measure,
	compact,
}) => {
	return (
		<div className={className}>
			{content.map((x, i) =>
				slateElementToComponent(
					x,
					`slate:${i}`,
					measure,
					headingMeasure,
					compact,
					bodyColor,
				),
			)}
		</div>
	);
};

export default SlateContent;
